import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
// @ts-ignore
import { FormProvider, useForm } from 'react-hook-form';
import { useMedia } from 'react-use';

import { routes } from 'config';
import { Typography } from 'components/ui/atoms';
import { Button } from 'components/ui/molecules';
import { breakpoints } from 'styles/breakpoints';
import { Close } from 'shared/icons';

import { useStyles } from './ChangePassword.styles';
import { ChangePasswordProps, FormFields } from './ChangePassword.types';
import { userClient } from 'clients/UserClient/UserClient';
import { LayoutContext } from 'shared/contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { TextFieldWithUseForm } from 'shared/components/TextField';

export const ChangePassword = ({ open, setOpen }: ChangePasswordProps) => {
  const { changesSaved } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const isMobile = useMedia(breakpoints.mobile);

  const methods = useForm<FormFields>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    },
  });
  const { handleSubmit, reset, watch, setError } = methods;

  const newPassword = watch('newPassword');

  const onSubmit = async (values: FormFields) => {
    try {
      await userClient.changeCurrentUserPassword({
        password: values.currentPassword,
        new_password: values.newPassword,
      });
      setOpen(false);
      reset();
      changesSaved();
    } catch (errors) {
      setError('currentPassword', { type: 'error', message: dictionary.incorrectPassword });
    }
  };
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullScreen={isMobile}>
      <div css={classes.root}>
        <div css={classes.close} onClick={() => setOpen(false)}>
          <Close />
        </div>
        <div css={classes.title}>
          <Typography variant={(v) => v.heading[2]} colorv2={(c) => c.neutral.variant[80]}>
            {dictionary.changePassword}
          </Typography>
        </div>
        <FormProvider {...methods}>
          <form css={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <div css={classes.row}>
              <TextFieldWithUseForm
                label={dictionary.currentPassword}
                type="password"
                name="currentPassword"
                rules={{
                  required: dictionary.required,
                }}
                contained
              />
              <Typography variant={(v) => v.body.short} colorv2={(c) => c.neutral.variant[60]}>
                {dictionary.remindPassword}{' '}
                <Link style={{ textTransform: 'lowercase' }} to={routes.recoverPassword}>
                  {dictionary.clickHere}
                </Link>
              </Typography>
            </div>
            <div css={classes.row}>
              <TextFieldWithUseForm
                label={dictionary.newPassword}
                type="password"
                name="newPassword"
                rules={{
                  required: dictionary.required,
                  minLength: {
                    message: dictionary.atLeast6Characters,
                    value: 6,
                  },
                }}
                contained
              />
            </div>
            <div css={classes.row}>
              <TextFieldWithUseForm
                label={dictionary.repeatNewPassword}
                type="password"
                name="repeatNewPassword"
                rules={{
                  required: dictionary.required,
                  validate: (value: any) => value === newPassword || dictionary.passwordsDoNotMatch,
                }}
                contained
              />
            </div>
            <div css={classes.ctas}>
              <Button variant="subtle" onClick={() => setOpen(false)} label={dictionary.cancel} />
              <Button variant="highlight" label={dictionary.save} submit />
            </div>
          </form>
        </FormProvider>
      </div>
    </Dialog>
  );
};
