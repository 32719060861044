import { Typography } from '@recruitrobin/robin-theme/web-components';
import { RobinLogoText } from '@recruitrobin/robin-theme/web-icons';
import { BaseCallbackError } from 'clients/UserClient/types';
import { Column, Row } from 'components/ui/atoms';
import AnimatedRobinLogo from 'components/ui/atoms/AnimatedRobinLogo';
import { Button } from 'components/ui/molecules';
import { routes } from 'config';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { CheckboxWithUseForm } from 'shared/components/CheckboxGroup';
import { SmallLanguageSwitch } from 'shared/components/localization/SmallLanguageSwitch/SmallLanguageSwitch';
import { TextFieldWithUseForm } from 'shared/components/TextField';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { ResetPasswordContext } from 'shared/contexts/ResetPasswordContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { useAuth } from 'shared/hooks';
import LoginBackground from 'shared/img/loginBackground.svg';
import { RequireAuthNavigateState } from 'views/Routes/components/RequireAuth/types';
import { useStyles } from './LogIn.styles';
import { FormFields } from './LogIn.types';

export const LogIn = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [enableLogoAnimation, setEnableLogoAnimation] = useState(false);
  const { login } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);
  const { setAuthentication } = useContext(ResetPasswordContext);
  const navigate = useNavigate();
  const location = useLocation();
  const fromUrl = (location.state as RequireAuthNavigateState)?.from?.pathname;

  useEffect(() => {
    if (auth.isLoggedIn && !enableLogoAnimation) {
      navigate(routes.search());
    }
  }, [auth.isLoading, auth.isLoggedIn, navigate]);

  const methods = useForm<FormFields>({
    defaultValues: {
      email: '',
      password: '',
      rememberClient: false,
    },
  });
  const { handleSubmit, setError } = methods;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = async ({ rememberClient, ...data }: FormFields) => {
    setIsLoading(true);
    setEnableLogoAnimation(true);

    if (!login) return;

    const logoAnimationPromise = new Promise<void>((res) =>
      setTimeout(() => {
        res();
      }, 1500),
    );

    try {
      const { status, data: response } = await login(data);
      await logoAnimationPromise;

      if (status === 201) {
        const { user_id, tenant_id, should_change_password, password_reset_code } = response;

        if (should_change_password) {
          if (password_reset_code) {
            navigate(routes.resetPasswordWithCode(user_id, password_reset_code, tenant_id), { replace: true });
            setIsLoading(false);
            return;
          }

          setAuthentication({ ...response, password: data.password });
          navigate(routes.resetPassword);
          setIsLoading(false);
          return;
        }

        navigate(fromUrl || routes.search(), { replace: true });
      }
    } catch (e) {
      const { response } = e as BaseCallbackError;
      const errorMessage = response?.data?.detail;

      setError('email', { type: 'error', message: errorMessage || dictionary.invalidUsername });
      setError('password', { type: 'error', message: errorMessage || dictionary.invalidPassword });
      setIsLoading(false);
    }
  };

  const classes = useStyles({});

  return (
    <div css={classes.root}>
      <div css={classes.loginForm}>
        <Row css={classes.logoWrapper}>
          <AnimatedRobinLogo size={75} enableAnimation={enableLogoAnimation} />
          <RobinLogoText height={78} color={(c) => c.neutral.variant[90]} />
        </Row>
        <Typography variant={(v) => v.heading[1]} color={(c) => c.neutral.variant[90]}>
          {dictionary.welcomeBack}
        </Typography>

        <FormProvider {...methods}>
          <form css={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Column css={classes.input}>
              <TextFieldWithUseForm
                label={dictionary.email}
                name="email"
                type="email"
                rules={{
                  required: dictionary.required,
                }}
                contained
              />
              <TextFieldWithUseForm
                label={dictionary.password}
                name="password"
                type="password"
                rules={{
                  required: dictionary.required,
                }}
                contained
              />
              <Row css={classes.options}>
                <CheckboxWithUseForm label={dictionary.rememberMe} name="rememberClient" />
                <Link to={routes.recoverPassword}>{dictionary.forgotYourPassword}</Link>
              </Row>
            </Column>

            <Button
              icon={isLoading ? <ClipLoader color="currentColor" size={18} /> : undefined}
              iconAlignment="right"
              variant="highlight"
              style="filled"
              label={dictionary.login}
              contained
              submit
            />
          </form>
        </FormProvider>
        <SmallLanguageSwitch />
      </div>

      <div css={classes.rightColumnWrapper}>
        <img src={LoginBackground} alt="image" css={classes.keyVisual} />
      </div>
    </div>
  );
};
